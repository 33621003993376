import { Box, Flex } from '@chakra-ui/react';
import { Link as ChakraLink } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Icon, Link, OnlineModeManager } from '@lon/shared/components';
import { isMobileDevice } from '@lon/shared/constants';
import { routes as appRoutes } from '@lon/suit/configs';

const fullHeight = isMobileDevice ? '100dvh' : '100vh';
const AuthorizedLayout: React.FC = () => {
  const { t } = useTranslation();

  return (
    <OnlineModeManager>
      <Flex h={fullHeight} maxH={fullHeight} flexDirection="column">
        <Box as="header">
          <Flex
            alignItems="center"
            py={4}
            px={8}
            m="0 auto"
            maxWidth="container.xl"
          >
            <ChakraLink
              as={Link}
              to={appRoutes.home}
              width="161px"
              height="38px"
              aria-label={t('header.backToDashboard')}
            >
              <Icon name="stemscopes-logo-lite" height="100%" width="100%" />
            </ChakraLink>
          </Flex>
        </Box>
        <Box backgroundColor="blue.75" flexGrow={1}>
          <Box p={8} m="0 auto" maxWidth="container.xl">
            <Outlet />
          </Box>
        </Box>
      </Flex>
    </OnlineModeManager>
  );
};

export default AuthorizedLayout;
