import { gql } from '@apollo/client';
import { SsStandardsItem } from '@lon/shared/gql/types';
import { GqlResponse } from '@lon/shared/types';

export type StandardsResponse = GqlResponse<SsStandardsItem, 'sStandardsItems'>;

export const GET_STANDARDS = gql`
  query getSsStandardsItems($filter: SsStandardsItemFilter) {
    ssStandardsItems(filter: $filter) {
      standard
    }
  }
`;
