import { gql } from '@apollo/client';
import { ClassPageConnection } from '@lon/shared/gql/types';
import { GqlResponse } from '@lon/shared/types';

export type ClassResponse = GqlResponse<ClassPageConnection, 'classes'>;

export const GET_CLASSES_FOR_SELECT_INPUT = gql(/* GraphQL */ `
  query getClassesForSelectInput(
    $teachersId: String
    $schoolId: String
    $archived: Boolean
  ) {
    classes(
      teachersId: $teachersId
      schoolId: $schoolId
      archived: $archived
      status: Active
      hasLmsId: false
    ) {
      collection {
        _id
        id
        name
        schoolId
        archived
      }
    }
  }
`);
