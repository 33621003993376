export const splitStandards = (standards: string[]) => {
  const standardsSplit = standards.map((standard) => {
    const parts = standard.split('.');

    const progressiveParts = parts.reduce(
      (progressiveParts: string[], part: string, index: number): string[] => {
        const currentPart =
          index === 0 ? part : progressiveParts[index - 1] + '.' + part;
        progressiveParts.push(currentPart);
        return progressiveParts;
      },
      []
    );
    return progressiveParts;
  });

  const uniqueStandards = [...new Set(standardsSplit.flat())];

  const sortedAlphaNumericallyStandards = uniqueStandards.sort((a, b) => {
    const regex = /(\d+|\D+)/g;
    const partsA = a.match(regex) || [];
    const partsB = b.match(regex) || [];

    // compare number parts and string parts of standards
    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const partA = partsA[i] || '';
      const partB = partsB[i] || '';

      const numA = parseInt(partA, 10);
      const numB = parseInt(partB, 10);

      if (!isNaN(numA) && !isNaN(numB)) {
        if (numA !== numB) {
          return numA - numB;
        }
      } else {
        if (partA !== partB) {
          return partA.localeCompare(partB);
        }
      }
    }

    return 0;
  });

  return sortedAlphaNumericallyStandards;
};
