import Grading from '../../../grading';
import GradingHeader from '../../../grading-header';
import { constants } from '../../duck';
import { Box, Button, useToast } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  ResponseFeedbackInput,
  useSaveGradingSessionMutation,
} from '@lon/shared/requests';
import { Answer } from '@lon/shared/types';
import { routes } from '@lon/suit/configs';
import { clearQueryCache } from "@lon/shared/utils";

const Form: React.FC<{
  isDa?: boolean;
  items: Answer[];
  defaultValues: Record<string, string>;
  assignmentId?: string;
  backPath?: string
}> = ({ isDa, items, defaultValues, assignmentId = '', backPath: backPathProps }) => {
  const { studentId, sessionId } = useParams();
  const [saveGrades] = useSaveGradingSessionMutation();
  const [savingGrades, setSavingGrades] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { t } = useTranslation();
  const formProviderProps = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  const onSubmit = (e: Record<string, string>) => {
    setSavingGrades(true);
    const responses = Object.keys(e)
      .filter((key) => key.startsWith('score_'))
      .map((key) => {
        const responseId = key.replace('score_', '');

        const currentResponse = items.find(
          (response) => response.responseId === responseId
        );

        const isNumeric = !isNaN(parseFloat(e[key]));

        if (currentResponse?.automarkable || !isNumeric) {
          return undefined;
        }

        const score = (Number(e[key]) * constants.maxScore) / 100;

        return {
          response_id: responseId,
          score: String(score),
          max_score: String(constants.maxScore),
        };
      })
      .filter(Boolean);
    const responseFeedbacks = Object.keys(e)
      .filter((key) => key.startsWith('feedback_'))
      .map((key) => {
        const responseId = key.replace('feedback_', '');
        const feedback = e[key];
        if (!feedback) {
          return undefined;
        }
        return {
          responseId: responseId,
          feedback,
        };
      })
      .filter((item): item is ResponseFeedbackInput => item !== undefined);
    saveGrades({
      variables: {
        sessionsParams: [
          {
            sessionId: sessionId as string,
            userId: studentId as string,
            responses: responses,
            responseFeedbacks,
          },
        ],
      },
      onCompleted: (response) => {
        const isUpdated = response?.saveGradingSession?.success;
        if (isUpdated) {
          // Learnosity does not update grades on their side immediately.
          setTimeout(() => {
            clearQueryCache('assignment')
            setSavingGrades(false);
            toast({
              title: t('manualGrading.successSave'),
              variant: 'success-light',
            });
            const backPath = location.state?.backPath
              ? location.state.backPath
              : generatePath(
                isDa
                  ? routes.classes.reportDashboard.districtAssessment
                  : routes.classes.assignments.view,
                {
                  assignmentId: assignmentId,
                }
              );

            navigate(backPathProps || backPath);
          }, 7000);
        } else {
          setSavingGrades(false);
          toast({
            title: t('systemMessages.038'),
            variant: 'error-light',
            isClosable: true,
          });
        }
      },
      onError: () => {
        setSavingGrades(false);
        toast({
          title: t('systemMessages.038'),
          variant: 'error-light',
          isClosable: true,
        });
      },
    });
  };

  useEffect(() => {
    // dispatch resize so that learnosity understands
    // that the initial layout changed
    window.dispatchEvent(new Event('resize'));
  }, []);

  return (
    <FormProvider {...formProviderProps}>
      <Box as="form" onSubmit={formProviderProps.handleSubmit(onSubmit)}>
        {items.map((item) => {
          const container = document
            .getElementById(item.responseId)
            ?.closest('.learnosity-question-container') as Element;

          if (!container) {
            return null;
          }

          return createPortal(
            <Grading
              responseId={item.responseId}
              hasScoring={item.automarkable === false}
              loading={savingGrades}
            >
              <GradingHeader response={item} />
            </Grading>,
            container
          ) as ReactElement;
        })}
        <Button
          variant="solid"
          type="submit"
          isDisabled={!formProviderProps.formState.isDirty || savingGrades}
          isLoading={savingGrades}
        >
          {t('studentAssignmentDetails.save')}
        </Button>
      </Box>
    </FormProvider>
  );
};

export default Form;
