import { Flex, Tag } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generateGradeStyles } from '@lon/suit/utils';
import { Response } from './duck';

interface GradingHeaderProps {
  response: Response;
}

const GradingHeader: React.FC<GradingHeaderProps> = ({ response }) => {
  const { t } = useTranslation();

  const hasScoring = !response.automarkable;
  const originalScore =
    typeof response.maxScore === 'number' && typeof response.score === 'number'
      ? Math.round((response.score / response.maxScore) * 100)
      : null;

  const inputScore = useWatch({ name: `score_${response.responseId}` });
  // TODO: add proper validation
  const numberInputScore = Number(inputScore);
  const limitedScore = numberInputScore > 100 ? 100 : numberInputScore;

  const score =
    hasScoring && typeof inputScore === 'number' ? limitedScore : originalScore;

  return (
    <Flex gap="4" marginBlock="4" pos="relative">
      <Tag
        borderRadius="12px"
        size="md"
        fontWeight="normal"
        color="white"
        bgColor="primary.800"
        fontSize="0.75rem"
        lineHeight="0.75rem"
        border="none"
      >
        {t('studentAssignmentDetails.grade')}
      </Tag>
      <Tag
        borderRadius="12px"
        size="md"
        fontWeight="normal"
        fontSize="0.75rem"
        bgColor="primary.30"
        color="primary.700"
        lineHeight="0.75rem"
        border="none"
      >
        {hasScoring
          ? t('studentAssignmentDetails.manualGrading')
          : t('studentAssignmentDetails.autoGraded')}
      </Tag>
      <Flex
        alignItems="center"
        justifyContent="center"
        borderRadius="25px"
        w="36px"
        h="36px"
        pos="absolute"
        right="0"
        top="-9px"
        {...generateGradeStyles(score)}
      >
        {typeof score === 'number' ? score : '-'}
      </Flex>
    </Flex>
  );
};

export default GradingHeader;
