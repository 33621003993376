import { AuthorizedLayoutOffline, HomepageOffline } from '../pages/offline';
import React from 'react';
import { Navigate, Route, createRoutesFromElements } from 'react-router-dom';

const AuthorizedOfflineRouter = createRoutesFromElements(
  <Route path="*" element={<AuthorizedLayoutOffline />}>
    <Route index element={<HomepageOffline />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Route>
);

export default AuthorizedOfflineRouter;
