import { t } from 'i18next';
import { array, boolean, object, string } from 'yup';

export const validationSchema = object({
  teams: array().of(
    object().shape({
      identifier: string(),
      archived: boolean(),
      name: string().required(
        t('validationMessages.requiredField', {
          fieldName: 'Team Name',
        })
      ),
      members: array()
        .of(
          object().shape({
            value: string(),
            label: string(),
          })
        )
        .min(
          1,
          t('validationMessages.requiredField', {
            fieldName: 'Team Members',
          })
        ),
    })
  ),
});

export const getInitialData = (
  data?: any,
  mainTeacher?: { id: string; firstName: string; lastName: string }
) => {
  const teams = data?.collection.map((member: any) => ({
    name: member?.name,
    identifier: member?.id,
    archived: member?.archived,
    members: member?.members
      ?.map((val: any) => ({
        value: `${val?.id}`,
        label: `${val?.firstName} ${val?.lastName}`,
      }))
      .sort((v: any) => (v?.isFixed ? -1 : 1)),
  }));
  if (!teams?.length) {
    return {
      teams: [],
    };
  }

  return {
    teams,
  };
};
export const getValidation = (data?: any) => ({
  validationSchema,
  defaultValues: getInitialData(data),
});
