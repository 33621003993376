import { Icon } from '../../../../../../../../../icon';
import { navigationTypes } from '../../duck';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react';
import { omit, toArray } from 'lodash-es';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sxLightScrollBar } from '@lon/shared/constants';
import { ScopeContext } from '@lon/shared/contexts';
import { checkContentPlayer } from '@lon/shared/utils';
import {
  ContentPlayerSectionCardWrapper,
  SuitSectionCardWrapper,
} from './components';

const ViewAllSectionsDrawer: React.FC<
  navigationTypes.ViewAllSectionsDrawerProps
> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { metadata, elementsBySection } = useContext(ScopeContext);

  const isContentPlayer = checkContentPlayer();
  const RenderSectionCard = !isContentPlayer
    ? SuitSectionCardWrapper
    : ContentPlayerSectionCardWrapper;

  const topBarElements = useMemo(() => {
    const sidebarSectionId = Object.keys(elementsBySection).find(
      (sectionId) => {
        const elements = toArray(elementsBySection[sectionId]);
        return Boolean(
          elements.find(
            (element) => element.metadata?.sectionType === 'Side Bar'
          )
        );
      }
    );

    return sidebarSectionId
      ? omit(elementsBySection, sidebarSectionId)
      : elementsBySection;
  }, [elementsBySection]);

  return (
    <Drawer
      isOpen={isOpen}
      variant="formDrawer"
      onClose={onClose}
      onEsc={onClose}
      onOverlayClick={onClose}
    >
      <DrawerOverlay />
      <DrawerContent boxShadow="none" maxW="1000px">
        <DrawerCloseButton top={{ base: '4', md: '7' }} />
        <DrawerHeader py={{ base: '4', md: '6' }}>
          <Text variant="formHeader">{t('scope.sectionsDrawerTitle')}</Text>
        </DrawerHeader>
        <DrawerBody>
          <Flex
            py="4"
            justify="center"
            boxShadow="0px 2px 7px 0px rgba(43, 54, 70, 0.10)"
            px={{ base: '8', md: '0' }}
          >
            <Text
              variant="h5"
              lineHeight="1.625rem"
              textAlign="left"
              color="primary.800"
              w="full"
              maxW="700px"
            >
              {metadata?.displayName}
            </Text>
          </Flex>
          <Flex
            justify="center"
            py="4"
            px={{ base: '4', md: '0' }}
            overflowY="auto"
            maxHeight="calc( 100% - 58px )"
            sx={{ scrollbarGutter: 'stable', ...sxLightScrollBar }}
          >
            <Flex maxW="700px" w="full" h="full" direction="column" gap="6">
              {Object.keys(topBarElements).map((sectionId) => {
                const sectionElements = toArray(elementsBySection[sectionId]);
                const sectionTitle =
                  sectionElements.find((element) => element?.sectionTitle)
                    ?.sectionTitle || '';
                const sectionIcon = sectionElements.find(
                  (element) => element?.metadata?.elementIconType
                )?.metadata?.elementIconType;

                return (
                  <RenderSectionCard
                    key={sectionId}
                    sectionTitle={sectionTitle}
                    sectionElements={sectionElements}
                    sectionIconType={sectionIcon}
                    handleCloseDrawer={onClose}
                  />
                );
              })}
            </Flex>
          </Flex>
        </DrawerBody>
        <DrawerFooter
          justifyContent="start"
          px={{ base: '4', md: '8' }}
          py={{ base: '4', md: '6' }}
        >
          <Button
            leftIcon={<Icon name="arr-left-outlined" />}
            onClick={onClose}
          >
            {t('scope.backToScope')}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ViewAllSectionsDrawer;
