import useFetchJson from '../useFetchJson';
import { ApolloQueryResult } from '@apollo/client';
import { useGetIsLeaderSuitFlag } from '@lon/shared/hooks';
import { Exact, GetScope2Query, useGetScope2Query } from '@lon/shared/requests';
import { ContentFilterer } from '@lon/suit/utils';

interface Props {
  scopeId: string;
  currentSchoolId: string | undefined;
  skip?: boolean;
}

type UseGetScope = ({ scopeId, currentSchoolId }: Props) => {
  loading: boolean;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            id: string;
            schoolId: string;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetScope2Query>>;
  called: boolean;
  scope: Record<string, any>;
};

const useGetScope: UseGetScope = ({ scopeId, currentSchoolId, skip }) => {
  const isLeaderSuit = useGetIsLeaderSuitFlag();
  const {
    loading: scopeUrlLoading,
    data: scopeUrlData,
    refetch,
    called,
  } = useGetScope2Query({
    variables: {
      ...(isLeaderSuit ? {} : { schoolId: currentSchoolId as string }),
      id: scopeId,
    },
    fetchPolicy: 'network-only',
    skip: skip || !currentSchoolId || !scopeId,
  });

  const scopeUrl = scopeUrlData?.baseScope?.jsonUrl;

  const contentGroups =
    scopeUrlData?.baseScope?.subscriptionContentGroups || [];

  const {
    data,
    loading: isScopeLoading,
    fetched: isScopeFetched,
  } = useFetchJson({
    url: scopeUrl as string,
    options: { credentials: 'include' },
  });

  const loading =
    scopeUrlLoading || isScopeLoading || (!isScopeFetched && !!scopeUrl);

  const scope = data
    ? new ContentFilterer(
        { ...data, id: scopeId },
        contentGroups
      ).getFilteredContent()
    : {};

  return { loading, scope, refetch, called };
};

export default useGetScope;
