import { appOperations } from '../../app/duck';
import {
  scopeConstants,
  scopeHooks,
} from '../teacher/scopes/components/all-scopes/components/show/duck';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useInterceptor } from '@lon/api';
import { AsyncOutlet } from '@lon/shared/components';
import { StudentGradeContext, WorkingLocation } from '@lon/shared/contexts';
import { ProfileTypeEnum } from '@lon/shared/enums';
import { useAuth, useCurrentSchoolId, useGradeLevel } from '@lon/shared/hooks';
import { useGetScope2Query } from '@lon/shared/requests';
import { JsonScopeStructure, ScopeContext } from '@lon/suit/contexts';
import { usePrevious } from '@lon/suit/hooks';
import {
  ContentFilterer,
  getCurrentSuite,
  transformElements,
} from '@lon/suit/utils';

const LtiContainer = () => {
  const [auth] = useAuth();
  const [currentSchoolId, setCurrentSchoolId] = useCurrentSchoolId();
  const params = useParams<{
    scopeId: string;
  }>();
  const { scopeId } = params;
  const { isStudentBelowK3 } = useGradeLevel();

  useInterceptor('request', appOperations.prepareRequest);

  useInterceptor('response', appOperations.prepareSuccess, (error) =>
    appOperations.prepareFailure({ error })
  );

  const workingLocation = useMemo(() => {
    return {
      application: getCurrentSuite(auth?.profileType),
      currentSchoolId,
      setCurrentSchoolId,
      permissions: (auth?.user.permissions || []) as any,
      schoolIds: auth?.user?.schoolIds || [],
      userType: auth?.user?.type,
    };
  }, [currentSchoolId, auth?.user]);

  const [attempts, setAttempts] = useState<number>(0);
  const prevAttempts = usePrevious(attempts);

  const { data: standardsJson } = scopeHooks.useFetchJsonData({
    url: scopeConstants.standardsUrl,
    options: {},
    skip: auth.profileType === ProfileTypeEnum.STUDENT,
  });

  const state = auth?.user?.state;

  const {
    data: scopeUrlData,
    loading: scopeUrlLoading,
    refetch,
    called,
  } = useGetScope2Query({
    variables: {
      schoolId: currentSchoolId,
      id: scopeId!,
    },
    fetchPolicy: 'network-only',
    skip: !currentSchoolId || !scopeId,
  });

  const scopeUrl = scopeUrlData?.baseScope?.jsonUrl;

  const {
    data,
    loading: scopeLoading,
    called: scopeCalled,
  } = scopeHooks.useFetchJsonData<JsonScopeStructure>({
    url: scopeUrl as string,
    options: { credentials: 'include' },
  });

  const contentGroups =
    scopeUrlData?.baseScope?.subscriptionContentGroups || [];

  const scope = data
    ? new ContentFilterer(
        { ...data, id: scopeId },
        contentGroups
      ).getFilteredContent()
    : {};

  const { elementsBySection } = React.useMemo(
    () => transformElements(scope as any, standardsJson, state),
    [scope, standardsJson, state]
  );

  const loading =
    scopeUrlLoading || scopeLoading || (!scopeCalled && !!scopeUrl);

  return (
    <WorkingLocation.Provider key={currentSchoolId} value={workingLocation}>
      <ScopeContext.Provider
        value={{
          original: data,
          loading,
          elementsBySection,
          refetch,
          attempts: prevAttempts,
          setAttempts,
          projectType: scope?.projectType,
          called,
          metadata: scope?.metadata,
        }}
      >
        <StudentGradeContext.Provider value={{ isStudentBelowK3 }}>
          <AsyncOutlet />
        </StudentGradeContext.Provider>
      </ScopeContext.Provider>
    </WorkingLocation.Provider>
  );
};

export default LtiContainer;
