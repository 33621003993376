import { openDB } from 'idb';
import { UseOfflineDbService } from '@lon/shared/types';

const DB_NAME = 'stem';
const STORE_NAME = 'suite';

const initDB = async () => {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'key' });
      }
    },
  });
};

const useOfflineDbService = () => {
  const setItem: UseOfflineDbService['setItem'] = async ({ key, value }) => {
    const db = await initDB();
    await db.put(STORE_NAME, { key, value });
  };

  const getItem: UseOfflineDbService['getItem'] = async ({ key }) => {
    const db = await initDB();
    const item = await db.get(STORE_NAME, key);
    return item?.value;
  };

  const deleteItem: UseOfflineDbService['deleteItem'] = async ({ key }) => {
    const db = await initDB();
    await db.delete(STORE_NAME, key);
  };

  const initStore = async () => {
    return await initDB();
  };

  return {
    setItem,
    getItem,
    deleteItem,
    initStore,
  };
};

export default useOfflineDbService;
