import { LoginOffline } from '../pages/offline';
import { Flex, Image } from '@chakra-ui/react';
import React from 'react';
import {
  Navigate,
  Outlet,
  Route,
  createRoutesFromElements,
} from 'react-router-dom';
import {
  OnlineModeManager,
  UnauthorizedFooter,
  UnauthorizedHeader,
} from '@lon/shared/components';

const OfflineRouter = createRoutesFromElements(
  <Route
    element={
      <OnlineModeManager>
        <Flex minH="100vh" flexDirection="column">
          <UnauthorizedHeader isSuite />
          <Flex
            flexGrow={1}
            position="relative"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              position="absolute"
              top="0"
              bottom="0"
              right="0"
              left="0"
              zIndex={-1}
              display="block"
              h="100%"
              w="100%"
              src="assets/login-bg.webp"
              alt=""
              role="presentation"
            />
            <Outlet />
          </Flex>
          <UnauthorizedFooter />
        </Flex>
      </OnlineModeManager>
    }
  >
    <Route path="login" element={<LoginOffline />} />
    <Route path="*" element={<Navigate to="login" />} />
  </Route>
);

export default OfflineRouter;
