import { HStack, Select, Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ClassesContext } from '@lon/shared/contexts';

const ClassesFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({});
  const { data, loading } = useContext(ClassesContext);

  const cls = searchParams.get('class') || '';

  const handleChange = (cls: string) => {
    if (!cls) {
      searchParams.delete('class');
    }
    const params = Object.fromEntries(searchParams.entries());

    setSearchParams({ ...params, ...(cls ? { class: cls } : {}) });
  };

  return (
    <HStack spacing={4} align="center" alignSelf="normal" flexBasis="50%">
      <Text variant="n3" color="primary.800" id="classes-label">
        {t('reportsDashboard.class')}
      </Text>
      <Select
        maxW="300px"
        aria-labelledby="classes-label"
        placeholder=" "
        value={cls}
        size="sm"
        color="primary.800"
        iconColor="primary.200"
        borderColor="primary.200"
        borderRadius="4px"
        autoComplete="off"
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        isDisabled={loading}
      >
        {data?.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Select>
    </HStack>
  );
};

export default ClassesFilter;
