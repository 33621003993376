import { useToast, useUpdateEffect } from '@chakra-ui/react';
import React from 'react';
import { OFFLINE_MODE_KEYS, isDevMode } from '@lon/shared/constants';
import { useOfflineDbService, useOnlineContext } from '@lon/shared/hooks';

const OnlineModeManager: React.FC<React.PropsWithChildren> = ({ children }) => {
  const toast = useToast();
  const { deleteItem, initStore } = useOfflineDbService();

  const { isOnline, setIsOnline } = useOnlineContext();

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  React.useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  React.useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          if (isDevMode()) {
            console.log(
              'Service Worker registered with scope:',
              registration.scope
            );
          }
        })
        .catch((error) => {
          if (isDevMode()) {
            console.error('Service Worker registration failed:', error);
          }
        });
    }
  }, []);

  const processOnlineStatus = async () => {
    try {
      if (isOnline) {
        await deleteItem({ key: OFFLINE_MODE_KEYS.auth.isLoggedIn });
      }
    } catch {
      toast({
        title: 'Error.',
        description: 'An error occurred while processing offline mode.',
        variant: 'error-light',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  React.useEffect(() => {
    initStore().catch(() => {});
  }, []);

  useUpdateEffect(() => {
    processOnlineStatus().catch(() => {});
  }, [isOnline]);

  return <>{children}</>;
};

export default OnlineModeManager;
