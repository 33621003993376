import { Box, Flex, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import format from 'date-fns/format';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StudentSuiteAssignmentStatus } from '@lon/shared/components';
import { StudentAssignmentAllStatusTransformed } from '@lon/shared/enums';
import { Table } from '@lon/shared/modules/table';

interface Columns {
  id: string;
  title: string;
  status: string;
  endDateDate: string | null;
  submittedAt: string | null;
  note: string | null;
}

const mockData = [
  {
    id: '1',
    title: 'Activity 1',
    status: 'assigned',
    endDateDate: '2025-01-01',
    submittedAt: null,
    note: null,
  },
  {
    id: '2',
    title: 'Activity 2',
    status: 'started',
    endDateDate: '2025-01-01',
    submittedAt: null,
    note: null,
  },
  {
    id: '3',
    title: 'Activity 3',
    status: 'submitted',
    endDateDate: null,
    submittedAt: '2024-01-01',
    note: 'Note 3',
  },
];

const Homepage: React.FC = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Columns>();

    return [
      columnHelper.accessor('title', {
        header: () => {
          return (
            <Text variant="ls" as="span">
              {t('grades.activityTitle')}
            </Text>
          );
        },
        cell: (info) => {
          return (
            <Text variant="s2" minW="120px">
              {info.getValue()}
            </Text>
          );
        },
      }),
      columnHelper.accessor('endDateDate', {
        header: () => {
          return (
            <Text variant="ls" as="span">
              {t('grades.due')}
            </Text>
          );
        },
        cell: (info) => {
          const val = info.getValue();
          return (
            <Text variant="s2" minW="120px">
              {val ? format(new Date(val), 'MM/dd/yyyy') : '-'}
            </Text>
          );
        },
      }),
      columnHelper.accessor('submittedAt', {
        header: () => {
          return (
            <Text variant="ls" as="span">
              {t('studentAssignments.columnSubmitted')}
            </Text>
          );
        },
        cell: (info) => {
          const val = info.getValue();
          return (
            <Text variant="s2" minW="120px">
              {val ? format(new Date(val), 'MM/dd/yyyy') : '-'}
            </Text>
          );
        },
      }),
      columnHelper.accessor('status', {
        header: () => {
          return (
            <Text variant="ls" as="span">
              {t('studentAssignments.columnStatus')}
            </Text>
          );
        },
        cell: (info) => {
          return (
            <StudentSuiteAssignmentStatus
              status={info.getValue() as StudentAssignmentAllStatusTransformed}
            />
          );
        },
      }),
    ];
  }, []);

  return (
    <Flex
      direction="column"
      backgroundColor="white"
      flexShrink={1}
      h="full"
      borderRadius="md"
      overflow="hidden"
    >
      <Box
        display="flex"
        gap={3}
        alignItems="center"
        px={{ base: 4, b768: 6, lg: 8 }}
        py={{ base: 4 }}
      >
        <Text variant="h5" color="primary.800">
          {t('assignments.title')}
        </Text>
      </Box>

      <Box borderTopWidth="1px" borderColor="secondary.200">
        <Table
          variant="secondary"
          columns={columns}
          data={mockData}
          headerProps={{
            bgColor: 'white',
            color: 'primary.800',
            whiteSpace: 'nowrap',
          }}
          containerProps={{
            border: 'none',
          }}
        />
      </Box>
    </Flex>
  );
};

export default Homepage;
