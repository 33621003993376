import { HStack, IconButton, Select, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Icon } from '@lon/shared/components';

interface Props {
  total: number;
  itemsPerPage: number;
}

const StandardsPagination: React.FC<Props> = ({ total, itemsPerPage }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({});

  const page = Number(searchParams.get('standards-page')) || 1;
  const numberOfPages = Math.ceil(total / itemsPerPage);
  const isDisableNext = page >= numberOfPages;
  const isDisabledPrev = page === 1;

  const handleChange = (page: number) => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...params, 'standards-page': page.toString() });
  };

  return (
    <HStack
      spacing="6"
      justify="space-between"
      w="fit-content"
      alignItems="center"
    >
      <HStack spacing="2">
        <IconButton
          size="sm"
          variant="empty"
          boxShadow="none"
          aria-label={t('pagination.firstPage')}
          onClick={() => {
            if (!isDisabledPrev) {
              handleChange(1);
            }
          }}
          isDisabled={isDisabledPrev}
          icon={<Icon name="arr-double-left-outlined" />}
        />
        <IconButton
          size="sm"
          variant="empty"
          boxShadow="none"
          aria-label={t('pagination.previousPage')}
          onClick={() => {
            if (!isDisabledPrev) {
              const currentPage = page - 1;
              handleChange(currentPage);
            }
          }}
          isDisabled={isDisabledPrev}
          icon={<Icon name="arr-left-outlined" />}
        />
      </HStack>
      <HStack spacing={4} align="center" alignSelf="normal">
        <Text variant="n3" color="primary.800">
          {t('reportsDashboard.page')}
        </Text>
        <Select
          value={page}
          size="sm"
          color="primary.800"
          iconColor="primary.200"
          borderColor="primary.200"
          borderRadius="4px"
          defaultValue={itemsPerPage}
          autoComplete="off"
          onChange={(e) => {
            handleChange(Number(e.target.value));
          }}
          isDisabled={total === 0}
        >
          {Array.from({ length: numberOfPages }).map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </Select>
      </HStack>
      <HStack spacing="2">
        <IconButton
          size="sm"
          variant="empty"
          boxShadow="none"
          transform="scaleX(-1)"
          aria-label={t('pagination.nextPage')}
          colorScheme="whiteAlpha"
          onClick={() => {
            if (!isDisableNext) {
              const currentPage = page + 1;

              handleChange(currentPage);
            }
          }}
          isDisabled={isDisableNext}
          icon={<Icon name="arr-left-outlined" />}
        />
        <IconButton
          size="sm"
          variant="empty"
          boxShadow="none"
          transform="scaleX(-1)"
          aria-label={t('pagination.lastPage')}
          colorScheme="whiteAlpha"
          onClick={() => {
            if (!isDisableNext) {
              handleChange(numberOfPages);
            }
          }}
          isDisabled={isDisableNext}
          icon={<Icon name="arr-double-left-outlined" />}
        />
      </HStack>
    </HStack>
  );
};

export default StandardsPagination;
