import ClassesFilter from '../classes-filter';
import Filters from '../filters';
import ReportLegend from '../report-legend';
import StandardsPagination from '../standards-pagination';
import { Box, Flex, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  total: number;
  itemsPerPage: number;
}

const Caption: React.FC<Props> = ({ total, itemsPerPage }) => {
  const { t } = useTranslation();

  return (
    <Flex flexDir="column">
      <Flex px={8} py={4} justify="space-between" align="center">
        <Box display="flex" gap={2} alignItems="center">
          <Text variant="h5" color="primary.800">
            {t('reportsDashboard.standardByStudents')}
          </Text>
        </Box>
        <Flex gap={3}>
          <Filters />
          <ReportLegend />
        </Flex>
      </Flex>
      <Flex
        justifyContent="space-between"
        borderTop="1px solid"
        borderTopColor="secondary.200"
        py={4}
        px={8}
      >
        <ClassesFilter />
        <StandardsPagination total={total} itemsPerPage={itemsPerPage} />
      </Flex>
    </Flex>
  );
};

export default Caption;
