import { gql } from '@apollo/client';
import { SsStandards } from '@lon/shared/requests';
import { GqlResponse } from '@lon/shared/types';

export type StandardReportResponse = GqlResponse<SsStandards, 'ssStandards'>;

export const GET_STANDARD_REPORT = gql`
  query getSsStandards {
    ssStandards {
      _id
      id
      resultSetJson
    }
  }
`;
