import {
  Box,
  Button,
  DrawerFooter,
  Flex,
  HStack,
  TabPanel,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { isEmpty, omit } from 'lodash-es';
import * as React from 'react';
import { useImperativeHandle, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConfirmModal, Icon, useToast } from '@lon/shared/components';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { useGetUserApplications, useGradeLevel } from '@lon/shared/hooks';
import {
  UserTypeEnum,
  useCreatePasswordChangeMutation,
  useUpdateUserMutation,
} from '@lon/shared/requests';
import { ApplicationSettingsReducer, Auth } from '@lon/shared/types';
import { clearQueryCache, handleError, parseJSON } from '@lon/shared/utils';
import ResetPassword from './components/reset-password/ResetPassword';
import { constants, types, utils } from './duck';
import {
  Administrators,
  DefaultLocation,
  ShowPassword,
  Teachers,
  UserInfo,
} from './components';

const AccessibilitySettings = React.forwardRef<
  {
    closeSettings: () => void;
    changeTab: (index: number) => void;
  },
  types.Props
>(({ setIsPreferencesOpen, setIsDirtyForm, setCurrentTab }, externalRef) => {
  const { user } = useSelector((state: any) => state.auth);
  const { isStudentBelowK3 } = useGradeLevel();
  const { t } = useTranslation();
  const toast = useToast();
  const { userId } = useSelector((state: { auth: Auth }) => state.auth.user);
  const [changeTab, setChangeTab] = useState<number | null>(null);
  const [createPasswordChange, { loading: createPasswordLoading }] =
    useCreatePasswordChangeMutation();
  const [updateUser, { loading: updateUserLoading }] = useUpdateUserMutation();
  const loading = createPasswordLoading || updateUserLoading;
  const [resetPasswordExpanded, setResetPasswordExpanded] =
    useState<number>(-1);
  const { application } = React.useContext(WorkingLocation);
  const applicationSettings = useSelector(
    (state: { applicationSettings: ApplicationSettingsReducer }) =>
      state.applicationSettings
  );
  const preferences = parseJSON(applicationSettings?.preferences) as any;
  const isStudent = user.type === UserTypeEnum.Student;
  const isRenderAdministrators =
    application !== ApplicationEnum.ADMIN && !isStudent;
  const isRenderTeachers = application !== ApplicationEnum.ADMIN && isStudent;
  const isRenderResetPassword = !isStudent;
  const isRenderShowPassword = isStudent;
  const isRenderDefaultLocation =
    application !== ApplicationEnum.MATHNATION_SUIT &&
    application !== ApplicationEnum.PLATFORM_ADMIN &&
    application !== ApplicationEnum.PARENT_SUIT;
  const {
    applications,
    uniqueAclApplication,
    loading: applicationsLoading,
  } = useGetUserApplications({
    skip: !user?.schoolIds || !isRenderDefaultLocation,
  });
  const validationSchema = utils.getValidationSchema(
    resetPasswordExpanded === -1 ? false : true
  );
  const defaultValues = React.useMemo(() => {
    const firstApplication = applications[0];
    const firstApplicationName = firstApplication?.application;
    const firstApplicationSchoolId =
      firstApplicationName === ApplicationEnum.MATHNATION_SUIT
        ? uniqueAclApplication?.[0]
        : firstApplication.value;

    return utils.getDefaultValues({
      defaultApplication:
        preferences?.defaultApplication || firstApplicationName,
      defaultSchool: preferences?.defaultSchool || firstApplicationSchoolId,
    });
  }, [applicationSettings, applications]);

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const fields = form.watch();

  const onSubmit = form.handleSubmit(async (values) => {
    const shouldChangePassword =
      !!values.currentPassword &&
      !!values.newPassword &&
      !!values.repeatedPassword;
    const shouldChangeDefaultLocation =
      defaultValues.defaultApplication !== values.defaultApplication ||
      defaultValues.defaultSchool !== values.defaultSchool;

    if (shouldChangePassword) {
      createPasswordChange({
        variables: {
          input: {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
            repeatedPassword: values.repeatedPassword,
          },
        },
      })
        .then(() => {
          if (typeof changeTab === 'number') {
            setCurrentTab(changeTab);
          } else {
            setIsPreferencesOpen(false);
          }

          toast({
            title: t('userSettings.credentials.success'),
            variant: 'success-light',
            isClosable: true,
            duration: 5000,
          });
        })
        .catch((error) => {
          if (error.message === constants.INVALID_PASSWORD) {
            toast({
              title: t('updatePassword.invalidPasswordTitle'),
              description: t(
                'updatePassword.invalidCurrentPasswordDescription'
              ),
              variant: 'error-light',
              isClosable: true,
              duration: 5000,
            });
          } else {
            handleError({ error, toast });
          }
        });
    }

    if (shouldChangeDefaultLocation) {
      const preferencesInput = !values.defaultSchool
        ? omit(preferences, 'defaultSchool')
        : preferences;
      const defaultLocation = {
        defaultApplication: values.defaultApplication,
        ...(values.defaultSchool
          ? { defaultSchool: values.defaultSchool }
          : {}),
      };

      updateUser({
        variables: {
          input: {
            id: `/api/user-management/api/users/${userId}`,
            preferences: JSON.stringify({
              ...preferencesInput,
              ...defaultLocation,
            }),
          },
        },
      })
        .then(() => {
          clearQueryCache('applicationSettings');
        })
        .catch((err) => {
          handleError({ err, toast } as any);
        });
    }
  });

  React.useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  React.useEffect(() => {
    setIsDirtyForm(!isEmpty(form.formState.dirtyFields));
  }, [fields]);

  const handleCloseSettings = () => {
    form.trigger();
    setChangeTab(null);

    if (!isEmpty(form.formState.dirtyFields)) {
      onConfirmOpen();
    } else {
      setIsPreferencesOpen(false);
    }
  };

  const handleChangeTab = (index: number) => {
    form.trigger();
    setChangeTab(index);

    if (!isEmpty(form.formState.dirtyFields)) {
      onConfirmOpen();
    } else {
      setCurrentTab(index);
    }
  };

  useImperativeHandle(
    externalRef,
    () => ({
      closeSettings: handleCloseSettings,
      changeTab: handleChangeTab,
    }),
    []
  );

  const closeForm = () => {
    if (form.formState.isDirty || !isEmpty(form.formState.dirtyFields)) {
      onConfirmOpen();
    } else {
      onClose(true);
    }
  };

  const onClose = (shouldCloseForm?: boolean) => {
    if (shouldCloseForm) {
      setIsPreferencesOpen(false);
    } else if (typeof changeTab === 'number') {
      setCurrentTab(changeTab);
    } else {
      setIsPreferencesOpen(false);
    }

    form.reset({});
    onConfirmClose();
  };

  return (
    <TabPanel as="form" p={0} noValidate onSubmit={onSubmit} w="full">
      <FormProvider {...form}>
        <Flex
          display="flex"
          direction="column"
          color="primary.800"
          h="calc(100vh - 9.25rem)"
        >
          <Box overflow="auto" h="calc(100vh - 9.25rem)" py={6}>
            <UserInfo isStudentBelowK3={isStudentBelowK3} />
            {isRenderResetPassword && (
              <ResetPassword
                expanded={resetPasswordExpanded}
                setExpanded={setResetPasswordExpanded}
              />
            )}
            {isRenderShowPassword && (
              <ShowPassword isStudentBelowK3={isStudentBelowK3} />
            )}
            {isRenderAdministrators && <Administrators />}
            {isRenderTeachers && (
              <Teachers isStudentBelowK3={isStudentBelowK3} />
            )}
            {isRenderDefaultLocation && (
              <DefaultLocation
                applications={applications}
                uniqueAclApplication={uniqueAclApplication}
                loading={applicationsLoading}
              />
            )}
          </Box>
          <DrawerFooter
            h="5.75rem"
            borderTopWidth="1px"
            justifyContent="flex-start"
            py={0}
            flex="none"
          >
            <HStack justify="space-between" width="100%">
              <Button
                leftIcon={<Icon name="arr-left-outlined" />}
                onClick={closeForm}
              >
                {t('userSettings.credentials.cancel')}
              </Button>
              <Button
                leftIcon={<Icon name="check-outlined" />}
                variant="solid"
                type="submit"
                isDisabled={loading || !form.formState.isDirty}
              >
                {t('userSettings.credentials.save')}
              </Button>
            </HStack>
          </DrawerFooter>
        </Flex>
      </FormProvider>
      <ConfirmModal
        primaryHeading={t('userSettings.credentials.modal.title')}
        subHeading={t('userSettings.credentials.modal.description')}
        dontSaveBtnText={t('userSettings.credentials.modal.cancel')}
        submitButtonText={t('userSettings.credentials.modal.save')}
        hasDontSaveBtn
        hasSaveBtn={form.formState.isValid}
        isModalOpen={isConfirmOpen}
        handleSave={() => {
          onSubmit();
          onConfirmClose();
          onClose();
        }}
        handleDontSave={onClose}
        handleCancel={onConfirmClose}
        isLoading={loading}
        dontSaveBtnProps={{
          variant: 'solidDark',
          leftIcon: <Icon name="delete-outlined" size="lg" />,
          px: 4,
        }}
        submitBtnProps={{
          leftIcon: <Icon name="check-outlined" size="md" />,
          px: 4,
        }}
        modalSize={{ base: 'full', sm: '500px' }}
      />
    </TabPanel>
  );
});

export default AccessibilitySettings;
