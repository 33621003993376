import { Grade } from '../components';
import { Box, Flex, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { t } from 'i18next';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Link, SortingIcon } from 'libs/shared/components/src';
import camelCase from 'lodash-es/camelCase';
import { generatePath } from 'react-router-dom';
import { SortEnum } from '@lon/shared/requests';
import { routes } from '@lon/suit/configs';
import { filterNullable } from '@lon/suit/utils';
import { ReponseObject, StandardRecord, StudentRecord } from './types';

export const getColumns = (data: string[] = []) => {
  const columnHelper = createColumnHelper<StudentRecord>();

  return [
    columnHelper.accessor('studentName', {
      cell: (info) => {
        const data = info?.row.original;

        return (
          <Box className="OneLinkNoTx" py={2}>
            <Link
              tabIndex={-1}
              to={generatePath(routes.classes.studentAssignments, {
                studentId: `${data.studentId}`,
              })}
            >
              <Text as="span" variant="su4" _hover={{ textDecoration: 'none' }}>
                {info.getValue()}
              </Text>
            </Link>
            <Text variant="s2">{data?.studentUsername}</Text>
          </Box>
        );
      },
      header: (data) => (
        <Flex>
          <Text as="span">{t('reportsDashboard.student') as string}</Text>
          <SortingIcon column={data?.column as any} />
        </Flex>
      ),
    }),
    ...(data.map((item) =>
      columnHelper.accessor(item, {
        id: item,
        cell: (info) => {
          const score = (info.row.original?.[item] as StandardRecord)?.scorePct;
          const grade =
            typeof score === 'undefined' || score === null
              ? undefined
              : Math.round(Number(score));

          return (
            <Flex justify="center">
              <Grade grade={grade} />
            </Flex>
          );
        },
        header: (data) => {
          return (
            <Flex flexDirection="column" alignItems="center" gap={2} py={2}>
              <Text
                color="primary.800"
                css={{
                  writingMode: 'vertical-lr',
                }}
                transform="rotate(180deg)"
              >
                {data?.header?.id}
              </Text>
              <SortingIcon column={data?.column as any} />
            </Flex>
          );
        },
      })
    ) || []),
  ];
};

export const prepareData = (
  data: ReponseObject | undefined,
  firstElement:
    | {
        VarCharValue: string;
      }[]
    | undefined
): StandardRecord[] => {
  const preparedData = data?.Rows?.map((row) => {
    return row.Data?.reduce<Record<string, string>>((acc, val, idx) => {
      const camelCaseKey = camelCase(firstElement?.[idx]?.VarCharValue);

      const value = val.VarCharValue;

      if (typeof value === 'undefined') {
        return acc;
      }

      return {
        ...acc,
        [camelCaseKey]: value,
      };
    }, {});
  }).filter(filterNullable);

  return preparedData as StandardRecord[];
};

export const mapStandardToStudent = (
  data: StandardRecord[]
): StudentRecord[] => {
  return data?.reduce<StudentRecord[]>((acc, val) => {
    const { studentId, standardList, studentUsername, studentName } = val;

    const standards = standardList
      .replace(/[[\]]/g, '')
      .split(',')
      .map((standard) => standard.trim());

    if (!standards || !standards.length) {
      return acc;
    }

    const standardRecord = val as StandardRecord;

    const studentIndex = acc.findIndex((item) => item.studentId === studentId);

    const standardMap = standards.reduce<Record<string, StandardRecord>>(
      (acc, val) => {
        return { ...acc, [val]: standardRecord };
      },
      {}
    );

    if (studentIndex === -1) {
      acc.push({
        studentId,
        studentUsername,
        studentName,
        ...standardMap,
      });
    } else {
      acc[studentIndex] = {
        ...acc[studentIndex],
        ...standardMap,
      };
    }

    return acc;
  }, []);
};

export const getOrder = (
  sorting: {
    id: string;
    desc: boolean;
  }[]
):
  | {
      [x: string]: SortEnum;
    }
  | undefined => {
  return sorting.length
    ? {
        [sorting[0].id === 'studentName' ? 'studentName' : 'scorePct']:
          sorting[0].desc ? SortEnum.Desc : SortEnum.Asc,
      }
    : undefined;
};
