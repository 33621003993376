import { extend } from 'lodash-es';
import { isArray, isEmpty } from 'lodash-es';
import { Dispatch, SetStateAction } from 'react';
import { Reports } from '@lon/shared/enums';
import { SignAssignmentServices } from '@lon/shared/requests';
import { FilterTypes } from '@lon/shared/types';
import { ReportType, SignedRequest, User } from './types';
import {
  CONTAINER_HTML_ELEMENT,
  LEARNOSITY_WRAPPER,
  RESPONSE_ANALYSIS,
} from './constants';

export const getReferencesById = (data: Record<string, any>) =>
  data.map((item: any) => item?.referenceLearnosityId ?? null);

export const mapUsers = (data: Record<string, any>) => {
  if (!data) return [];
  const userList = data.map((item: Record<string, any>) => {
    return {
      id: item.studentId,
      name: `${item.firstName} ${item.lastName}`,
    };
  });
  return [...new Map(userList.map((item: User) => [item.id, item])).values()];
};

const checkReferenceIds = (
  report: ReportType,
  data: Record<string, any>,
  activityIds?: Record<string, any>
) => {
  if (activityIds && activityIds.length > 0) {
    return extend(report, {
      activity_ids: activityIds,
    });
  }

  const referenceIds: string[] = getReferencesById(data);
  const filterReferenceIds: string[] | undefined = [...new Set(referenceIds)];
  const isReferencesIdsEmpty = filterReferenceIds.find((ref) => Boolean(ref));

  if (!isReferencesIdsEmpty) {
    return report;
  }

  return extend(report, {
    activity_ids: isReferencesIdsEmpty ? filterReferenceIds : [],
  });
};

export const getVariables = ({
  type,
  data,
  getId,
  filters,
  activityIds,
}: {
  data: Record<string, any>;
  type: string;
  getId?: string;
  filters?: FilterTypes;
  activityIds?: Record<string, any>;
}) => {
  if (!type) {
    throw new Error('Type must not be empty');
  }

  if (type === Reports.RESPONSE_ANALYSIS) {
    const referencesIds = getReferencesById(data);

    if (!getId) {
      throw new Error('Assignment Id must not be empty');
    }

    if (!referencesIds.length) {
      throw new Error('There are no data in the report');
    }

    const mappedUsers = mapUsers(data);
    return {
      containerId: type,
      activityId: getId,
      activities: [...new Set(referencesIds)],
      users: mappedUsers,
      type,
    };
  }

  if (type === Reports.LEARNING_OUTCOMES) {
    const mappedUsers = mapUsers(data);

    if (!mappedUsers.length) {
      throw new Error('There must be at least one user');
    }

    const report: ReportType = {
      id: type,
      type: type,
      users: mappedUsers as string[],
      items_tags_live_dataset_reference: 'items-tags-live-dataset-v2',
      session_items_live_dataset_reference: 'session-items-live-dataset-v2',
      column_tag_types: [
        'standard-1',
        'standard-2',
        'standard-3',
        'standard-4',
        'standard-5',
      ],
      label_bundle: {
        total: 'label report',
      },
    };
    extend(report, filters);

    return {
      referenceId: '',
      service: SignAssignmentServices.Reports,
      reports: JSON.stringify([checkReferenceIds(report, data, activityIds)]),
    };
  }

  throw new Error('Report type does not exist');
};

export const createReport = (
  type: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setLearnosityError: Dispatch<SetStateAction<string | undefined>>
) => {
  const checkSignedRequest = (signedRequest: string) => {
    if (isEmpty(signedRequest)) {
      throw new Error('There was a problem fetching the data');
    }

    const parsed: SignedRequest = JSON.parse(signedRequest);
    const reports = parsed.request.reports;

    if (reports[0].type === RESPONSE_ANALYSIS) {
      if (!reports[0]?.session_ids) {
        throw new Error(
          'None of the 100 students have completed this assignment'
        );
      }
    }
  };

  const removeReportContainer = () => {
    const wrapperDiv = document.querySelector(`#${LEARNOSITY_WRAPPER}`);
    if (wrapperDiv) {
      while (wrapperDiv.firstChild) {
        wrapperDiv.removeChild(wrapperDiv.firstChild);
      }
    }
  };

  const createReportContainer = () => {
    const div = document.createElement(CONTAINER_HTML_ELEMENT);
    div.id = type;
    document.getElementById(LEARNOSITY_WRAPPER)?.append(div);
    return document.getElementById(type);
  };

  const initLearnosity = (signedRequest: string) => {
    try {
      window.LearnosityReports.init(JSON.parse(signedRequest), {
        readyListener,
        errorListener,
        scoreMutator,
      });

      onError('');
    } catch (e: any) {
      console.error(e);
      onError('There was a problem fetching the data');
    }
  };

  const initReport = (signedRequest: string) => {
    removeReportContainer();
    createReportContainer();
    try {
      checkSignedRequest(signedRequest);
      initLearnosity(signedRequest);
    } catch (error: any) {
      onError(error.message);
    }
  };

  const readyListener = () => {
    setLoading(false);
    console.log('ready');
  };

  const scoreMutator = (scores: any) => {
    scores.forEach(function (score: any) {
      const maxScore =
        score.correct() +
        score.incorrect() +
        score.unmarked() +
        score.unattempted();
      let performanceBand = 'none';

      if (maxScore > 0) {
        const percent = Math.round((score.correct() / maxScore) * 100);
        if (percent >= 80) {
          performanceBand = '4';
        } else if (percent >= 70) {
          performanceBand = '3';
        } else if (percent >= 60) {
          performanceBand = '2';
        } else if (percent >= 0) {
          performanceBand = '1';
        }
      }
      score.domData({
        'performance-band': performanceBand,
      });
    });
  };

  const errorListener = (e: any) => {
    console.error(e.msg);
    onError(e.msg);
  };

  const onError = (error: string) => {
    if (setLearnosityError && error) {
      setLoading(false);
      if (
        error !== 'Error processing request' &&
        error !== 'Request aborted due to size of response'
      ) {
        return setLearnosityError(error);
      }
    }
  };

  return { initReport, removeReportContainer };
};

export const getReport = async (variables: any) => {
  try {
    const results = await fetch(
      'http://api.ali.localhost:8000/learnosity-api/api/graphql',
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `query GetReport(
            $containerId: String!,
            $activityId: String,
            $activities: Iterable,
            $limit: Int,
            $displayUser: Boolean,
            $users: Iterable,
            $userId: String,
            $type: String!,
            $sessionId: String,
        ) {
        getReport (
          containerId: $containerId
          activityId: $activityId
          activities: $activities
          users: $users
          userId: $userId
          type: $type
          limit: $limit
          displayUser: $displayUser,
          sessionId: $sessionId
        ) {
          id
          __typename
        }
      }`,
          variables,
        }),
      }
    );

    const report = await results.json();
    if (report.errors) {
      if (isArray(report.errors)) {
        return report.errors.map((err: any) =>
          console.error(err.debugMessage ?? err.message)
        );
      }
    }
    return report || '';
  } catch (error) {
    console.error(error);
  }
};

export const extractSignedRequest = ({ data }: { data: any }) => {
  if (data?.signRequest?.signedRequest) {
    return data.signRequest.signedRequest;
  }

  if (data?.getReport?.signedRequest) {
    return data?.getReport?.signedRequest;
  }

  return '';
};
