import { t } from 'i18next';
import { object, string } from 'yup';

const schema = object({
  pin: string()
    .required(
      t('validationMessages.requiredField', {
        fieldName: 'PIN Code',
      })
    )
    .default(''),
});

export const getValidation = () => ({
  validationSchema: schema,
  defaultValues: schema.cast({}),
});

export type FormValues = ReturnType<typeof schema.cast>;
