import { Button, Flex, Image, Text, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Asterisk, TextInput } from '@lon/shared/components';
import {
  ERROR_MESSAGES,
  OFFLINE_MODE_KEYS,
  isDevMode,
} from '@lon/shared/constants';
import { useOfflineDbService, useOnlineContext } from '@lon/shared/hooks';
import { FormValues, getValidation } from './duck';

const LoginOffline: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  const { getItem, setItem } = useOfflineDbService();
  const { setIsLogged } = useOnlineContext();

  const { validationSchema, defaultValues } = getValidation();
  const formProviderProps = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      const storedPin = await getItem({ key: OFFLINE_MODE_KEYS.auth.pin });

      if (storedPin !== values.pin) {
        toast({
          title: ERROR_MESSAGES.INVALID_CREDENTIALS,
          variant: 'error-light',
          isClosable: true,
          duration: 5000,
        });

        return;
      }

      await setItem({ key: OFFLINE_MODE_KEYS.auth.isLoggedIn, value: true });
      setIsLogged(true);

      navigate('/');
    } catch {
      toast({
        title: ERROR_MESSAGES.GENERAL_ERROR,
        variant: 'error-light',
        isClosable: true,
        duration: 5000,
      });
    }
  };

  const handleSetMock = () => {
    setItem({ key: OFFLINE_MODE_KEYS.auth.pin, value: 'GV1532' });
  };

  return (
    <FormProvider {...formProviderProps}>
      <Flex
        as="form"
        direction="column"
        justifyContent="space-between"
        data-testid="login-form"
        noValidate
        w="100%"
        role="tabpanel"
        id="tab-0-panel"
        aria-labelledby="tab-0"
        onSubmit={formProviderProps.handleSubmit(handleSubmit)}
        minWidth={{ base: '100%', sm: '480px', md: '712px' }}
        maxWidth={{ base: '100%', sm: '480px', md: '712px' }}
        minH="525px"
        zIndex="10"
        px={{ base: 5, sm: 10, md: 12 }}
        py={{ base: 3, sm: 6, md: 10 }}
        gap={8}
        boxShadow=" 0 4px 4px rgba(0, 0, 0, 0.25)"
        background="linear-gradient(180deg, #1E417C 26.12%, rgba(30, 65, 118, 0.8) 72.14%)"
      >
        <Flex justify="space-between" align="center">
          <Image
            width="108px"
            height="73px"
            alt="STEM, Focused curriculum"
            src="assets/stem_focused_curriculum.svg"
          />
          <Text
            as="h1"
            fontSize="1.5rem"
            variant="n3"
            color="white"
            letterSpacing="3px"
          >
            {t('offline.login.heading')?.toLocaleUpperCase()}
          </Text>
        </Flex>

        <TextInput
          variant="dark"
          name="pin"
          placeholder={t('offline.login.pinCodePlaceholder')}
          label={t('offline.login.pinCode')}
          formLabelProps={{
            requiredIndicator: <Asterisk color="#f78e20" />,
          }}
          isRequired
        />

        <Button
          type="submit"
          size="md"
          variant="solidDark"
          alignSelf="flex-end"
        >
          {t('offline.login.loginButton')}
        </Button>

        {isDevMode() && <Button onClick={handleSetMock}>Set Mock</Button>}
      </Flex>
    </FormProvider>
  );
};

export default LoginOffline;
