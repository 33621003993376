import { Loader } from '../loader';
import React from 'react';
import { OFFLINE_MODE_KEYS, isDevMode } from '@lon/shared/constants';
import { OnlineModeContext } from '@lon/shared/contexts';
import { useOfflineDbService } from '@lon/shared/hooks';

const OnlineModeContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isOnline, setIsOnline] = React.useState(true);
  const [isLogged, setIsLogged] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const { deleteItem, getItem } = useOfflineDbService();

  React.useEffect(() => {
    let resultOnline = false;

    if (isDevMode()) {
      setIsLoading(false);
      // resultOnline = false;
      //
      // getItem({ key: OFFLINE_MODE_KEYS.auth.isLoggedIn }).then((isLoggedIn) => {
      //   if (isLoggedIn) {
      //     setIsLogged(true);
      //   }
      // });
      return;
    }

    fetch(`${window.location.origin}/suite/content-management.js`, {
      method: 'HEAD',
      cache: 'no-store',
    })
      .then((resp) => {
        if (!resp.ok) {
          resultOnline = false;
          setIsOnline(false);
          return;
        }

        resultOnline = true;
        deleteItem({ key: OFFLINE_MODE_KEYS.auth.isLoggedIn }).catch(() => {});
      })
      .catch(() => {
        setIsOnline(false);
        resultOnline = false;
      })
      .finally(() => {
        if (!resultOnline) {
          getItem({ key: OFFLINE_MODE_KEYS.auth.isLoggedIn }).then(
            (isLoggedIn) => {
              if (isLoggedIn) {
                setIsLogged(true);
              }
            }
          );
        }

        setIsOnline(resultOnline);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <OnlineModeContext.Provider
      value={{ isOnline, setIsOnline, isLogged, setIsLogged }}
    >
      {children}
    </OnlineModeContext.Provider>
  );
};

export default OnlineModeContextProvider;
