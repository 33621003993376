import { gql } from '@apollo/client';
import { SsStandards } from '@lon/shared/requests';
import { GqlResponse } from '@lon/shared/types';

export type StandardReportResponse = GqlResponse<SsStandards, 'ssStandards'>;

export const GET_STANDARD_REPORT = gql`
  query getSsStandards(
    $classId: String
    $districtId: String
    $nextToken: String
    $order: SsStandardsSort
    $itemsPerPage: Int
    $schoolId: String
    $standards: String
    $teacherId: String
  ) {
    ssStandards(
      classId: $classId
      districtId: $districtId
      nextToken: $nextToken
      order: $order
      itemsPerPage: $itemsPerPage
      schoolId: $schoolId
      standards: $standards
      teacherId: $teacherId
    ) {
      _id
      id
      resultSetJson
      nextToken
    }
  }
`;
