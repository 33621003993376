import { Flex, Text } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Select } from '@lon/shared/components';
import { Standards } from '@lon/shared/contexts';
import { useQueryParams } from '@lon/shared/hooks';
import { splitStandards } from './duck';

const StandardsList = () => {
  const { t } = useTranslation();
  const [params, setParams] = useQueryParams<{
    standard: string;
  }>();
  const { data } = useContext(Standards);
  const standard = params.get('standard') || '';
  const [value, setValue] = useState({ value: standard, label: standard });
  const splitedStandards = splitStandards(data);

  const options = splitedStandards.map((item) => ({
    label: item,
    value: item,
  }));

  useEffect(() => {
    if (standard !== value.value) {
      setValue({ value: standard, label: standard });
    }
  }, [standard, value.value]);

  return (
    <Flex p={6} gap={4} background="white" alignItems="center">
      <Text
        id="selectLabel"
        as="label"
        variant="h5"
        lineHeight="1.625rem"
        ml={8}
        color="primary.800"
        flexShrink={0}
      >
        {t('reportsDashboard.standardsLevel')}
      </Text>
      <Select
        aria-labelledby="selectLabel"
        name="standards"
        placeholder="Select Standard"
        value={value}
        onChange={(item) => {
          if (!item) {
            return;
          }

          setValue(item);
          setParams(
            {
              ...params,
              standard: item?.value as string,
            },
            { replace: true }
          );
        }}
        options={options}
      />
    </Flex>
  );
};

export default StandardsList;
